<template>
  <transition name="slide">
    <div>
      <div class="row">
        <AppSelect
          id="users-search"
          v-model="selected_search_by"
          class="col-4"
          :options="search_by_options"
          :placeholder="$t('search_by') || 'search by'"
          :field-label="$t('search_by') || 'search by'"
        />
        <AppSelect
          id="user_select"
          ref="user_select"
          class="col-8"
          v-model="selected_user"
          :placeholder="$t('select_user') || 'Select Users'"
          :field-label="$t('user') || 'Users'"
          :label="$t('user') || 'Users'"
          :options="judges"
          :class="required ? 'required' : ''"
          :rules="required ? 'required' : ''"
          v-bind="$attrs"
          store-module="judges"
          :search-key="selected_search_by ? selected_search_by.value : 'name'"
          search-store-action="judges/fetchAllData"
          :search-params="{ actor: 3 }"
          v-on="$listeners"
          @input="addUserToComity"
        />
      </div>
      <!-- <transition name="slide">
        <div v-if="selected_user">
          <h5>{{ $t("search_results") || "Search Results" }}</h5>
          <UserCard
            v-if="selected_user"
            :user="selected_user"
            :comity="comity"
            @addUserToComity="addUserToComity"
          />
        </div>
      </transition> -->
      <transition name="slide">
        <section v-if="comity && comity.users && comity.users.length">
          <hr />
          <h5>{{ $t("comity_users") || "Comity Users" }}</h5>

          <b-list-group>
            <b-list-group-item
              v-for="(user, index) in comity.users"
              :key="index"
              class="p-0"
            >
              <transition name="slide">
                <div v-if="user">
                  <UserCard
                    :user="user"
                    :comity="comity"
                    variant="success"
                    @addUserToComity="addUserToComity"
                  />
                </div>
              </transition>
            </b-list-group-item>
          </b-list-group>
        </section>
      </transition>
    </div>
  </transition>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
// import userCan from "@/mixins/UserCan";
import UserCard from "@/components/UI/user/UserCard.vue";
import { AppSelect } from "@/components/form/index";

export default {
  name: "UsersSelect",
  components: {
    AppSelect,
    UserCard,
    BListGroup,
    BListGroupItem,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    comity: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected_search_by: null,
      selected_user: null,
      search_by_options: [
        { value: "name", label: this.$t("name") || "Name" },
        { value: "email", label: this.$t("email") || "Email" },
        {
          value: "mobile_number",
          label: this.$t("mobile_number") || "Mobile Number",
        },
      ],
    };
  },
  computed: {
    judges() {
      return this.$store.state.judges.data;
    },
    isLoading() {
      return this.$store.state.judges.isLoading;
    },
  },
  // created() {
  //   this.$store.dispatch("judges/fetchAllData");
  // },
  methods: {
    searchUsersHandler(searchText) {
      this.$store.dispatch("judges/fetchAllData", {
        actor: 3,
        name: searchText,
      });
    },
    addUserToComity(item) {
      this.$emit("addUserToComity", item);
    },
  },
};
</script>
