<template>
  <div>
    <AppTable
      store-module="comity"
      :table-fields="tableFields"
      :filters="filters"
      :actions="actions"
      :table-header="tableHeader"
      outer-form="comity-form"
      @displayForm="displayForm"
    />

    <ComityForm
      v-if="userCan('comity-edit') || userCan('comity-add')"
      :id="'comity-form'"
      :comity="activeItem"
      @hide="hideForm"
    />
  </div>
</template>

<script>
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";
import ComityForm from "./partials/ComityForm.vue";
import userCan from "@/mixins/UserCan";

export default {
  name: "Comity",
  components: {
    AppTable,
    ComityForm,
  },
  data() {
    return {
      activeItem: {},
      tableFields: [
        { key: "edit_delete", label: i18n.t("actions") || "Actions" },
        { key: "name_ar", label: i18n.t("name_ar") || "name_ar" },
        { key: "users", label: i18n.t("users") || "users" },
      ],
      filters: [{ name: "createdAt", componentType: "AppDatePicker" }],
      actions: [
        {
          label: i18n.t("edit") || "Edit",
          variant: "primary",
          event: "edit",
          condition: "edit",
          // storeAction: "editAction",
        },
        {
          label: i18n.t("delete") || "delete",
          variant: "danger",
          event: "delete",
          condition: "delete",
          // storeAction: "editAction",
        },
      ],
      tableHeader: {
        addNewBtn: true,
        multiKeySearch: true,
      },
    };
  },
  computed: {},
  watch: {
    // awards(val) {
    //   this.formFields[1].attrs.options = val;
    // },
  },

  methods: {
    userCan,
    displayForm(item = null) {
      this.activeItem = item;
      // this.$bvModal.show("comity-form");
    },
    hideForm() {
      this.activeItem = {};
    },
  },
};
</script>
