<template>
  <AppForm
    :id="id"
    ref="comityForm"
    class="p-1"
    :title="
      comity
        ? $t('edit_form', { name: comity.name_ar }) || `Edit ${comity.name_ar}`
        : $t('add_new_comity') || 'Add New Comity'
    "
    :errors-bag="errorsBag"
    :success-message="successMessage"
    :is-loading="isLoading"
    @hide="hideForm"
    @submit.prevent="handleSubmit"
  >
    <AppInput
      v-model="comityData.name_ar"
      type="text"
      required
      :label="$t('comity') || 'Comity'"
    />

    <b-form-group :label="$t('in_home') || 'In home'" label-for="in_home">
      <b-form-checkbox
        id="in_home"
        v-model="comityData.in_home"
        data-test="in_home"
        name="in_home"
        value="1"
        unchecked-value="0"
      >
        {{ $t("in_home") || "In home" }}
      </b-form-checkbox>
    </b-form-group>

    <UsersSelect :comity="comityData" @addUserToComity="addUserToComity" />
  </AppForm>
</template>

<script>
import { BFormCheckbox, BFormGroup } from "bootstrap-vue";
import { AppInput, AppSelect, AppForm } from "@/components/form/index";
import { toFormData } from "@/utils/index";
import UsersSelect from "./UsersSelect.vue";

export default {
  components: {
    AppInput,
    AppForm,
    AppSelect,
    UsersSelect,
    BFormCheckbox,
    BFormGroup,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    comity: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      comityData: {
        name_ar: null,
        in_home: "0",
        users: [],
      },
      successMessage: null,
    };
  },
  mounted() {
    this.$store.dispatch("judges/fetchAllData", { actor: 3 });
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.comity.isLoading.creating ||
        this.$store.state.comity.isLoading.updating ||
        this.$store.state.comity.isLoading.fetching
      );
    },
    errorsBag() {
      return this.$store.state.comity.errors;
    },
  },
  watch: {
    comity: {
      handler(val) {
        if (!val) {
          this.comityData = {
            name_ar: null,
            in_home: "0",
            users: [],
          };
          return;
        }
        this.comityData = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    addUserToComity(user) {
      if (!user) return;

      if (this.comityData.users.find((u) => u.id === user.id)) {
        this.comityData.users = this.comityData.users.filter(
          (u) => u.id !== user.id
        );
        return;
      }

      this.comityData.users.push(user);
    },
    handleSubmit() {
      const comityName = this.comity ? "updateData" : "createData";

      this.$store
        .dispatch(`comity/${comityName}`, toFormData(this.comityData))
        .then(() => {
          this.hideForm();
          this.successMessage = this.$t("comity_was_successfully_saved");
        });
    },
    hideForm() {
      this.$root.$emit("bv::hide::modal", this.id);
      this.$store.dispatch("comity/resetModule");

      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
